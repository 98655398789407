import { store } from "redux-store/index";
import { setMenuState } from "redux-store/reducers/common";

export function openSidebar() {
  if (typeof document !== "undefined") {
    document.body.style.overflow = "hidden";
    document.documentElement.style.setProperty("--SideNavigation-slideIn", "1");
    setTimeout(() => {
      store.dispatch(setMenuState(true));
    }, 500);
  }
}

export function closeSidebar() {
  if (typeof document !== "undefined") {
    document.documentElement.style.removeProperty("--SideNavigation-slideIn");
    document.body.style.removeProperty("overflow");
    setTimeout(() => {
      store.dispatch(setMenuState(false));
    }, 500);
  }
}

export function toggleSidebar() {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--SideNavigation-slideIn");

    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}

export function isMobile() {
  if (typeof window !== "undefined") {
    return window.innerWidth <= 600;
  }
  return false;
}

export function isAusa() {
  if (typeof window !== "undefined") {
    const ausa = "ausavina.com";
    return window.location.hostname.includes(ausa);
  }
  return false;
}
