import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider
} from "@mui/material/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { isCustomerDomain } from "app-constants";
import { ConfirmWrapper } from "components/ConfirmUtils";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { fetchMe } from "redux-store/reducers/auth";
import { setReady } from "redux-store/reducers/common";
import { getListEmployee, getRole } from "redux-store/reducers/employee";
import { getProductItems } from "redux-store/reducers/product";
import router from "routers";
import theme, { materialTheme } from "theme";
import { initFirebase } from "utils/app-firebase/firebase";
import GlobalCss from "utils/globalCss";
import { NotificationProvider } from "utils/notification";
import { isAusa } from "utils/ui-uitils";

const App = () => {
  const dispatch = useAppDispatch();
  const ready = useAppSelector((state) => state.common.ready);
  const lang = useAppSelector((state) => state.common.lang);
  const auth = useAppSelector((state) => state.auth);
  const triggerReload = useAppSelector((state) => state.common.triggerReload);

  useEffect(() => {
    if (isCustomerDomain) {
      if (auth.session?.access_token) {
        dispatch(fetchMe({ isCustomer: true }))
          .then(unwrapResult)
          .then(({ response }) => {
            dispatch(setReady(true));
            if (!auth?.isCustomer && response.data?.id) {
              dispatch(getRole({}));
              dispatch(getProductItems({}));
              dispatch(getListEmployee({}));
            }
            if (!isAusa()) {
              initFirebase({
                is_customer: !!auth?.isCustomer,
              });
            }

          });
      } else {
        dispatch(setReady(true));
      }
    } else {
      if (auth.session?.access_token && auth.session?.secret_code) {
        dispatch(fetchMe({ isCustomer: false }))
          .then(unwrapResult)
          .then(({ response }) => {
            dispatch(setReady(true));
            if (!auth?.isCustomer && response.data?.id) {
              dispatch(getRole({}));
              dispatch(getProductItems({}));
              dispatch(getListEmployee({}));
            }
            if (!isAusa()) {
              initFirebase({
                is_customer: !!auth?.isCustomer,
              });
            }

          });
      } else {
        dispatch(setReady(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.session?.access_token, auth.session?.secret_code]);

  if (!ready) {
    return null;
  }

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider
        key={lang + Date.now() + triggerReload}
        disableNestedContext
        theme={theme}
      >
        <GlobalCss />
        <NotificationProvider>
          <RouterProvider router={router} />
        </NotificationProvider>

        <ConfirmWrapper />
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

export default App;
