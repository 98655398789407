import {
  AddBoxOutlined,
  DocumentScannerOutlined,
  FactoryOutlined,
  LocalShippingOutlined,
  LowPriorityOutlined,
  QrCodeScannerOutlined,
  SettingsOutlined,
  TaskOutlined,
  WidgetsOutlined
} from "@mui/icons-material";
import CategoryOutlined from "@mui/icons-material/CategoryOutlined";
import InsertChartOutlined from "@mui/icons-material/InsertChartOutlined";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PersonAddAltOutlined from "@mui/icons-material/PersonAddAltOutlined";
import PriceChangeOutlined from "@mui/icons-material/PriceChangeOutlined";
import ReceiptOutlined from "@mui/icons-material/ReceiptOutlined";
import ShoppingBasketOutlined from "@mui/icons-material/ShoppingBasketOutlined";
import WarehouseOutlined from "@mui/icons-material/WarehouseOutlined";
import { Badge, Stack } from "@mui/joy";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { setSession } from "redux-store/reducers/auth";
import { getOpenAction } from "redux-store/reducers/sales-order";
import ROUTERS, { AUSABACO_ROUTERS, ausabacoScreensEnum, notificationBadge, screensEnum } from "routers/constants";
import AppEvent from "utils/app-events";
import useListenEmitEvent from "utils/common-hooks/useListenEmitEvent";
import { getAllowAccessRoute } from "utils/roles";
import { closeSidebar } from "utils/ui-uitils";
import ColorSchemeToggle from "./ColorSchemeToggle";
import ConfirmUtils from "./ConfirmUtils";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const isOpenMenu = useAppSelector((state) => state.common.isOpenMenu);

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  const openActionReport = useAppSelector(
    (state) => state.salesOrder.openActionReport
  );

  const handleLogout = (e: any) => {
    e.stopPropagation();
    ConfirmUtils.sure("Logout").then((isOk) => {
      if (isOk) {
        dispatch(setSession(null));
      }
    });
  };
  const gotoMenu = (routeName: string) => {
    const isMobileDevice = /Mobi/i.test(navigator.userAgent);
    if (isMobileDevice) closeSidebar();
    navigate(routeName);
  };

  const gotoProfile = () => {
    const isMobileDevice = /Mobi/i.test(navigator.userAgent);
    if (isMobileDevice) closeSidebar();
    navigate(ROUTERS.PROFILE);
  };

  useEffect(() => {
    if (isOpenMenu) {
      document.documentElement.style.setProperty(
        "--SideNavigation-slideIn",
        "1"
      );
    } else {
      document.documentElement.style.removeProperty("--SideNavigation-slideIn");
    }
    dispatch(getOpenAction({}))
  }, []);

  useListenEmitEvent({
    event: AppEvent.types.NOTI_PULL,
    matchCondition: (payload) => true,
    handle: () => {
      dispatch(getOpenAction({}))
    },
  });

  const menuList = [
    {
      name: "Dashboard",
      icon: <InsertChartOutlined fontSize="small" />,
      routeName: ROUTERS.DASHBOARD,
      id: screensEnum.DASHBOARD,
    },
    {
      name: "Product",
      icon: <CategoryOutlined fontSize="small" />,
      routeName: ROUTERS.PRODUCT,
      id: screensEnum.PRODUCT,
    },
    {
      name: "Inventory",
      icon: <QrCodeScannerOutlined fontSize="small" />,
      routeName: ROUTERS.INVENTORY,
      id: screensEnum.INVENTORY,
    },

    {
      name: "Customer",
      icon: <PersonAddAltOutlined fontSize="small" />,
      routeName: ROUTERS.CUSTOMER,
      id: screensEnum.CUSTOMER,
    },

    {
      name: "Lead",
      icon: <PersonAddAltOutlined fontSize="small" />,
      routeName: ROUTERS.LEAD,
      id: screensEnum.LEAD,
    },

    {
      name: "Quotation",
      icon: <LocalAtmIcon fontSize="small" />,
      routeName: ROUTERS.QUOTATION,
      id: screensEnum.QUOTATION,
    },

    {
      name: "Sales Order",
      icon: <ReceiptOutlined fontSize="small" />,
      routeName: ROUTERS.SALES_ORDER,
      id: screensEnum.SALES_ORDER,
    },

    {
      name: "Sales Report",
      icon: <ReceiptOutlined fontSize="small" />,
      routeName: ROUTERS.SALES_REPORT,
      id: screensEnum.SALES_REPORT,
    },

    {
      name: "Backorders",
      icon: <LowPriorityOutlined fontSize="small" />,
      routeName: ROUTERS.SALES_ORDER_BACKORDER,
      id: screensEnum.SALES_ORDER_BACKORDER,
      notificationBadge: notificationBadge.BACKORDER
    },

    {
      name: "PPS Processing",
      icon: <AddBoxOutlined fontSize="small" />,
      routeName: ROUTERS.SALES_ORDER_PICK,
      id: screensEnum.SALES_ORDER_PICK,
    },
    {
      name: "Invoice",
      icon: <PriceChangeOutlined fontSize="small" />,
      routeName: ROUTERS.INVOICE,
      id: screensEnum.INVOICE,
    },
    {
      name: "Manufacturing",
      icon: <FactoryOutlined fontSize="small" />,
      routeName: ROUTERS.MANUFACTURING,
      id: screensEnum.MANUFACTURING,
    },
    {
      name: "Purchase Order",
      icon: <ShoppingBasketOutlined fontSize="small" />,
      routeName: ROUTERS.PURCHASE,
      id: screensEnum.PURCHASE,
    },
    {
      name: "Assisted Purchase",
      icon: <ShoppingBasketOutlined fontSize="small" />,
      routeName: ROUTERS.ASSISTED_PURCHASE,
      id: screensEnum.ASSISTED_PURCHASE,
    },
    {
      name: "Receive",
      icon: (
        <DocumentScannerOutlined
          fontSize="small"
          sx={{
            transform: "rotate(90deg)",
          }}
        />
      ),
      routeName: ROUTERS.RECEIVE,
      id: screensEnum.RECEIVE,
    },
    {
      name: "Contract",
      icon: (
        <TaskOutlined
          fontSize="small"
        />
      ),
      routeName: AUSABACO_ROUTERS.CONTRACT,
      id: ausabacoScreensEnum.CONTRACT,
    },

    // {
    //   name: "Item Audit",
    //   icon: (
    //     <PublishedWithChangesOutlined
    //       fontSize="small"
    //     />
    //   ),
    //   routeName: ROUTERS.AUDIT,
    //   id: screensEnum.AUDIT,
    // },

    // {
    //   name: "Item Scan",
    //   icon: (
    //     <DocumentScannerOutlined
    //       fontSize="small"
    //       sx={{
    //         transform: "rotate(90deg)",
    //       }}
    //     />
    //   ),
    //   routeName: ROUTERS.ITEM_SCAN,
    //   id: screensEnum.ITEM_SCAN,
    // },

    // {
    //   name: "Pallet",
    //   icon: <WidgetsOutlined fontSize="small" />,
    //   routeName: ROUTERS.PALLET,
    //   id: screensEnum.PALLET,
    // },
    {
      name: "Box",
      icon: <WidgetsOutlined fontSize="small" />,
      routeName: ROUTERS.BOX,
      id: screensEnum.BOX,
    },
    {
      name: "Scan Box",
      icon: (
        <QrCodeScannerOutlined
          fontSize="small"
          sx={{
            transform: "rotate(90deg)",
          }}
        />
      ),
      routeName: ROUTERS.BOX_PACKING,
      id: screensEnum.BOX_PACKING,
    },
    {
      name: "Scan Pallet",
      icon: (
        <DocumentScannerOutlined
          fontSize="small"
          sx={{
            transform: "rotate(90deg)",
          }}
        />
      ),
      routeName: ROUTERS.PALLET_PACKING,
      id: screensEnum.PALLET_PACKING,
    },
    {
      name: "Container",
      icon: <LocalShippingOutlined fontSize="small" />,
      routeName: ROUTERS.CONTAINER,
      id: screensEnum.CONTAINER,
    },
    // {
    //   name: "Return",
    //   icon: <ReplyAllOutlined fontSize="small" />,
    //   routeName: ROUTERS.RETURN,
    //   id: screensEnum.RETURN,
    // },

    // {
    //   name: "Promotion",
    //   icon: <DiscountOutlined fontSize="small" />,
    //   routeName: ROUTERS.PROMOTION,
    //   id: screensEnum.PROMOTION,
    // },
    // {
    //   name: "Accounting",
    //   icon: <CurrencyExchangeOutlined fontSize="small"/>,
    //   routeName: ROUTERS.ACCOUNTING,
    // },
    {
      name: "Warehouse",
      icon: <WarehouseOutlined fontSize="small" />,
      routeName: ROUTERS.WAREHOUSE,
      id: screensEnum.WAREHOUSE,
    },
    {
      name: "Employee",
      icon: <PeopleOutlineIcon fontSize="small" />,
      routeName: ROUTERS.EMPLOYEE,
      id: screensEnum.EMPLOYEE,
    },

    {
      name: "Settings",
      icon: <SettingsOutlined fontSize="small" />,
      routeName: ROUTERS.SETTINGS,
      id: screensEnum.SETTINGS,
    },

    // {
    //   name: "Tasks",
    //   icon: <ChecklistIcon fontSize="small" />,
    //   routeName: ROUTERS.TASKS,
    //   id: screensEnum.TASKS,
    // },
  ].filter((item) => getAllowAccessRoute(item.id as any));

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: "fixed",
        transform:
          "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
        transition: "transform 0.4s, width 0.4s",
        zIndex: 120,
        height: "100dvh",
        width: "240px",
        top: 0,
        p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            md: "translateX(-100%)",
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * 240px))",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <img src="/LOGO-Web.png" width={64} alt="Abaco" />
        <Stack>
          <Typography level="title-lg">Abaco</Typography>
          <small style={{ fontSize: "10px" }}>Business Platform</small>
          <small style={{ fontSize: "10px" }}>
            Version: {process.env.REACT_APP_PUBLIC_VERSION}
          </small>
        </Stack>

        <ColorSchemeToggle sx={{ ml: "auto" }} />
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            "--List-nestedInsetStart": "30px",
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
          }}
        >
          {menuList.map((item, index) => (
            <ListItem key={index}>
              <ListItemButton
                onClick={() => gotoMenu(item.routeName)}
                selected={location.pathname?.startsWith(item.routeName)}
              >
                {item.icon}
                <ListItemContent>
                  <Badge size="sm" badgeContent={openActionReport?.backorderNeedProcessCount || 0} invisible={!item.notificationBadge}><Typography level="title-sm">{item.name}</Typography></Badge>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {/* version */}
        {/* 
        <Box sx={{ mt: "auto", textAlign: "center" }}>
          <Typography level="body-xs" color="neutral">
            {process.env.REACT_APP_PUBLIC_VERSION}
          </Typography>
        </Box> */}
      </Box>
      <Divider />
      <Box
        sx={{ display: "flex", gap: 1, alignItems: "center", cursor: "pointer" }}
        onClick={gotoProfile}
      >
        <Avatar variant="outlined" size="sm" />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{user?.name}</Typography>
          <Typography level="body-xs" letterSpacing={-1}>
            {user?.roleName}
          </Typography>
        </Box>
        <IconButton
          size="sm"
          variant="plain"
          color="neutral"
          onClick={handleLogout}
        >
          <LogoutRoundedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Sheet>
  );
}
