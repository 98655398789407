import { isCustomerDomain } from "app-constants";

import { createBrowserRouter } from "react-router-dom";
import adminRoutes from "./AdminRoutes";
import customerRoutes from "./CustomerRoutes";


const router = createBrowserRouter(isCustomerDomain ? customerRoutes : adminRoutes);

export default router;
