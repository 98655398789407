import { IBranch } from "types/customer";
import { ICustomerAddress, ICustomerContact } from "types/customer-portal-so";
import { IDepositsItem } from "types/invoice";
import { IShipmentPackage } from "types/sales-order";
import { Request } from "utils/request";

export const getListCustomerAddressApi = () => {
  return Request.call<{ data: ICustomerAddress[] }>({
    url: "/customer-portal/address",
    method: "GET",
  });
};

export const updateCustomerAddressApi = (
  payload: ICustomerAddress & {
    fromAdmin?: boolean;
  }
) => {
  return Request.call<{ data: ICustomerAddress }>({
    url: payload.fromAdmin
      ? "/customer/address/update"
      : "/customer-portal/address/update",
    method: "PUT",
    data: {
      ...payload,
      fromAdmin: undefined,
    },
  });
};

export const createCustomerAddressApi = (
  payload: ICustomerAddress & {
    fromAdmin?: boolean;
  }
) => {
  return Request.call<{ data: ICustomerAddress }>({
    url: payload.fromAdmin
      ? "/customer/address/create"
      : "/customer-portal/address/create",
    method: "PUT",
    data: {
      ...payload,
      fromAdmin: undefined,
    },
  });
};

export const deleteCustomerAddressApi = (payload: {
  id: any;
  customer_id?: any;
  fromAdmin?: boolean;
}) => {
  return Request.call<{ data: ICustomerAddress }>({
    url: payload.fromAdmin
      ? "/customer/address/delete"
      : "/customer-portal/address/delete",
    method: "PUT",
    data: {
      ...payload,
      fromAdmin: undefined,
    },
  });
};

export const setDefaultCustomerAddressApi = (payload: {
  id: any;
  customer_id?: any;
}) => {
  return Request.call<{ data: ICustomerAddress }>({
    url: "/customer/address/set-default",
    method: "PUT",
    data: {
      ...payload,
    },
  });
};

// contact
export const getListCustomerContactApi = () => {
  return Request.call<{ data: ICustomerContact[] }>({
    url: "/customer-portal/contact/list",
    method: "PUT",
  });
};
export const updateCustomerContactApi = (
  payload: ICustomerContact & {
    fromAdmin?: boolean;
  }
) => {
  return Request.call<{ data: ICustomerContact }>({
    url: payload.fromAdmin
      ? "/customer/contact/update"
      : "/customer-portal/contact/update",
    method: "PUT",
    data: {
      ...payload,
      fromAdmin: undefined,
    },
  });
};

export const createCustomerContactApi = (
  payload: ICustomerContact & {
    fromAdmin?: boolean;
  }
) => {
  return Request.call<{ data: ICustomerContact }>({
    url: payload.fromAdmin
      ? "/customer/contact/create"
      : "/customer-portal/contact/create",
    method: "PUT",
    data: {
      ...payload,
      fromAdmin: undefined,
    },
  });
};

export const deleteCustomerContactApi = (payload: {
  id: any;
  customer_id?: any;
  fromAdmin?: boolean;
}) => {
  return Request.call<{ data: ICustomerContact }>({
    url: payload.fromAdmin
      ? "/customer/contact/delete"
      : "/customer-portal/contact/delete",
    method: "PUT",
    data: {
      ...payload,
      fromAdmin: undefined,
    },
  });
};

// end contact
export const getCustomerPOPackagesApi = (payload: { so_id: any }) => {
  return Request.call<{ data: IShipmentPackage[] }>({
    url: "/customer-portal/sales-order/package/list",
    method: "PUT",
    data: payload,
  });
};

export const getCustomerPODepositListApi = (payload: { so_id: any }) => {
  return Request.call<{ data: IDepositsItem[] }>({
    url: "/customer-portal/sales-order/deposit/list/",
    method: "PUT",
    data: payload,
  });
};

export const listBranchApi = () => {
  return Request.call<{ data: IBranch[] }>({
    url: "/customer-portal/branch/list",
    method: "GET",
  });
};

export const createUpdateBranchApi = (payload: IBranch) => {
  return Request.call<{ data: IBranch }>({
    url: payload?.id
      ? "/customer-portal/branch/update"
      : "/customer-portal/branch/create",
    method: "PUT",
    data: payload,
  });
};
