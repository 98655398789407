import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import { persistEncryptReducer } from "redux-store/redux-utils";

import {
  createCustomerAddressApi,
  createCustomerContactApi,
  createUpdateBranchApi,
  deleteCustomerAddressApi,
  deleteCustomerContactApi,
  getCustomerPODepositListApi,
  getCustomerPOPackagesApi,
  getListCustomerAddressApi,
  getListCustomerContactApi,
  listBranchApi,
  setDefaultCustomerAddressApi,
  updateCustomerAddressApi,
  updateCustomerContactApi,
} from "services/customer-portal/customer-portal-so.service";

import { CustomerAddressList } from "types/customer-portal-so";

const TYPE = "CUSTOMER_PORTAL_SALES_ORDER";

export interface ICustomerPortalSalesOrderState {
  addressList?: CustomerAddressList;
}

const initialState: ICustomerPortalSalesOrderState = {
  addressList: [],
};

export const getListCustomerAddress = createCallApiAsyncAction(
  TYPE + "/getListCustomerAddress",
  getListCustomerAddressApi
);

// address
export const updateCustomerAddress = createCallApiAsyncAction(
  TYPE + "/updateCustomerAddress",
  updateCustomerAddressApi
);

export const createCustomerAddress = createCallApiAsyncAction(
  TYPE + "/createCustomerAddress",
  createCustomerAddressApi
);

export const deleteCustomerAddress = createCallApiAsyncAction(
  TYPE + "/deleteCustomerAddress",
  deleteCustomerAddressApi
);

export const setDefaultCustomerAddress = createCallApiAsyncAction(
  TYPE + "/setDefaultCustomerAddress",
  setDefaultCustomerAddressApi
);

// end address

// contact
export const getListCustomerContact = createCallApiAsyncAction(
  TYPE + "/getListCustomerContact",
  getListCustomerContactApi
);
export const updateCustomerContact = createCallApiAsyncAction(
  TYPE + "/updateCustomerContact",
  updateCustomerContactApi
);

export const createCustomerContact = createCallApiAsyncAction(
  TYPE + "/createCustomerContact",
  createCustomerContactApi
);

export const deleteCustomerContact = createCallApiAsyncAction(
  TYPE + "/deleteCustomerContact",
  deleteCustomerContactApi
);
// end contact

export const getCustomerPOPackages = createCallApiAsyncAction(
  TYPE + "/getCustomerPOPackages",
  getCustomerPOPackagesApi
);

export const getCustomerPODepositList = createCallApiAsyncAction(
  TYPE + "/getCustomerPODepositList",
  getCustomerPODepositListApi
);

export const listBranch = createCallApiAsyncAction(
  TYPE + "/listBranch",
  listBranchApi
);

export const createUpdateBranch = createCallApiAsyncAction(
  TYPE + "/createUpdateBranch",
  createUpdateBranchApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListCustomerAddress,
      stateKey: "addressList",
    });
  },
  reducers: {},
});

export default persistEncryptReducer(TYPE, slice.reducer, [
  "addressList",
]) as typeof slice.reducer;
