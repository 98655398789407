import { store } from "redux-store";
import ROUTERS, {
  AUSABACO_ROUTERS,
  ausabacoScreensEnum,
  screensEnum,
} from "routers/constants";
import { isAusa } from "./ui-uitils";

// Role list
export enum RolesEnum {
  Administrator = "Administrator",
  CEO = "CEO",
  COO = "COO",
  Director = "Director",
  GeneralManager = "General Manager",

  Processor = "Processor",
  ManagerProcessor = "Manager Processor",
  SalesRep = "Sales Rep",
  CustomerService = "Customer Service",

  Traffic = "Traffic",

  USWarehouse = "US Warehouse",

  Accounting = "US Accounting",
  AccountingManager = "US Accounting Manager",

  VietnamPlanning = "Vietnam Planning",
  VietnamAccounting = "Vietnam Accounting",
  VietnamFactory = "Vietnam Factory",

  VietnameSale = "Vietnam Sale",
}

const roles = [
  RolesEnum.Administrator,
  RolesEnum.CEO,
  RolesEnum.COO,
  RolesEnum.Director,
  RolesEnum.GeneralManager,

  RolesEnum.Processor,
  RolesEnum.ManagerProcessor,
  RolesEnum.SalesRep,
  RolesEnum.CustomerService,

  RolesEnum.Traffic,

  RolesEnum.USWarehouse,

  RolesEnum.Accounting,
  RolesEnum.AccountingManager,

  RolesEnum.VietnamPlanning,
  RolesEnum.VietnamAccounting,
  RolesEnum.VietnamFactory,
  RolesEnum.VietnameSale,
];

const AdminRoles = [
  RolesEnum.Administrator,
  RolesEnum.CEO,
  RolesEnum.COO,
  RolesEnum.Director,
  RolesEnum.GeneralManager,
];

const SalesRoles = [
  RolesEnum.Processor,
  RolesEnum.ManagerProcessor,
  RolesEnum.SalesRep,
  RolesEnum.CustomerService,
];

const VietnamSalesRoles = [
  RolesEnum.SalesRep,
];

const WarehouseRoles = [RolesEnum.USWarehouse];

const AccountingRoles = [RolesEnum.Accounting, RolesEnum.AccountingManager];

// Screen names
type TScreen = keyof typeof ROUTERS;
const screens = (
  Object.keys({ ...ROUTERS, ...AUSABACO_ROUTERS }) as TScreen[]
).filter(
  (v) =>
    ![screensEnum.NOT_FOUND, screensEnum.LOGIN, screensEnum.PROFILE].includes(v)
);

let routeRoleMap = {} as {
  [key in TScreen]: {
    [key in RolesEnum]?: boolean;
  };
};

type TRouteRoleMap = {
  [key in RolesEnum]: {
    allow: string[] | "all";
    notAllow: string[] | "all";
  };
};
const customRoleMap: TRouteRoleMap = {
  ...AdminRoles.reduce((acc, role) => {
    acc[role] = {
      allow: "all",
      notAllow: [],
      // notAllow: [ausabacoScreensEnum.CONTRACT],
    };
    return acc;
  }, {} as TRouteRoleMap),

  ...SalesRoles.reduce((acc, role) => {
    acc[role] = {
      allow: [
        screensEnum.DASHBOARD,
        screensEnum.INVENTORY,
        screensEnum.INVENTORY_DETAIL,

        screensEnum.CUSTOMER,
        screensEnum.CUSTOMER_DETAIL,
        screensEnum.CUSTOMER_NEW,

        screensEnum.LEAD,
        screensEnum.LEAD_DETAIL,
        screensEnum.LEAD_NEW,

        screensEnum.PURCHASE,
        screensEnum.PURCHASE_DETAIL,
        screensEnum.PURCHASE_NEW,

        screensEnum.QUOTATION,
        screensEnum.QUOTATION_DETAIL,
        screensEnum.QUOTATION_NEW,

        screensEnum.ASSISTED_PURCHASE,
        screensEnum.ASSISTED_PURCHASE_DETAIL,
        screensEnum.ASSISTED_PURCHASE_NEW,

        screensEnum.SALES_ORDER,
        screensEnum.SALES_ORDER_BACKORDER,
        screensEnum.SALES_ORDER_BACKORDER_DETAIL,
        screensEnum.SALES_ORDER_DETAIL,
        screensEnum.SALES_ORDER_NEW,
        screensEnum.SALES_ORDER_PICK,
        screensEnum.SALES_ORDER_PICK_DETAIL,
      ],
      notAllow: "all",
    };
    return acc;
  }, {} as TRouteRoleMap),

  ...AccountingRoles.reduce((acc, role) => {
    acc[role] = {
      allow: [
        screensEnum.DASHBOARD,
        screensEnum.INVENTORY,
        screensEnum.INVENTORY_DETAIL,

        screensEnum.CUSTOMER,
        screensEnum.CUSTOMER_DETAIL,
        screensEnum.CUSTOMER_NEW,

        screensEnum.SALES_REPORT,

        screensEnum.LEAD,
        screensEnum.LEAD_DETAIL,
        screensEnum.LEAD_NEW,

        screensEnum.PURCHASE,
        screensEnum.PURCHASE_DETAIL,

        screensEnum.ASSISTED_PURCHASE,
        screensEnum.ASSISTED_PURCHASE_DETAIL,

        screensEnum.SALES_ORDER,
        screensEnum.SALES_ORDER_BACKORDER,
        screensEnum.SALES_ORDER_BACKORDER_DETAIL,
        screensEnum.SALES_ORDER_DETAIL,

        screensEnum.INVOICE,
        screensEnum.INVOICE_DETAIL,
      ],
      notAllow: "all",
    };
    return acc;
  }, {} as TRouteRoleMap),

  ...WarehouseRoles.reduce((acc, role) => {
    acc[role] = {
      allow: [
        screensEnum.DASHBOARD,
        screensEnum.INVENTORY,
        screensEnum.INVENTORY_DETAIL,

        screensEnum.SALES_ORDER_PICK,
        screensEnum.SALES_ORDER_PICK_DETAIL,

        screensEnum.RECEIVE,

        screensEnum.ITEM_SCAN,
      ],
      notAllow: "all",
    };
    return acc;
  }, {} as TRouteRoleMap),

  [RolesEnum.Traffic]: {
    allow: [
      screensEnum.DASHBOARD,
      screensEnum.INVENTORY,
      screensEnum.INVENTORY_DETAIL,

      screensEnum.MANUFACTURING,
      screensEnum.MANUFACTURING_DETAIL,

      screensEnum.PURCHASE,
      screensEnum.PURCHASE_DETAIL,
      screensEnum.PURCHASE_NEW,

      screensEnum.ASSISTED_PURCHASE,
      screensEnum.ASSISTED_PURCHASE_DETAIL,
      screensEnum.ASSISTED_PURCHASE_NEW,

      screensEnum.RECEIVE,
      screensEnum.ITEM_SCAN,

      screensEnum.SALES_ORDER,
      screensEnum.SALES_ORDER_DETAIL,
      screensEnum.SALES_ORDER_NEW,
      screensEnum.SALES_ORDER_PICK,
      screensEnum.SALES_ORDER_PICK_DETAIL,
    ],
    notAllow: "all",
  },
  [RolesEnum.VietnamAccounting]: {
    allow: [
      screensEnum.DASHBOARD,
      screensEnum.INVENTORY,
      screensEnum.INVENTORY_GRID,
      screensEnum.PRODUCT_IMPORT,
      screensEnum.PURCHASE,
      screensEnum.PURCHASE_DETAIL,
      screensEnum.ASSISTED_PURCHASE,
      screensEnum.ASSISTED_PURCHASE_DETAIL,
    ],
    notAllow: "all",
  },
  [RolesEnum.VietnamPlanning]: {
    allow: [
      screensEnum.DASHBOARD,
      screensEnum.PRODUCT,
      screensEnum.PRODUCT_DETAIL,
      screensEnum.PRODUCT_NEW,
      screensEnum.INVENTORY,
      screensEnum.INVENTORY_DETAIL,

      screensEnum.QUOTATION,
      screensEnum.QUOTATION_DETAIL,

      screensEnum.MANUFACTURING,
      screensEnum.MANUFACTURING_DETAIL,

      screensEnum.PURCHASE,
      screensEnum.PURCHASE_DETAIL,

      screensEnum.ASSISTED_PURCHASE,
      screensEnum.ASSISTED_PURCHASE_DETAIL,

      screensEnum.PALLET,
      screensEnum.PALLET_DETAIL,
      screensEnum.PALLET_PACKING,

      screensEnum.BOX,
      screensEnum.BOX_PACKING,

      screensEnum.CONTAINER,
      screensEnum.CONTAINER_DETAIL,
    ],
    notAllow: "all",
  },
  [RolesEnum.VietnamFactory]: {
    allow: [
      screensEnum.DASHBOARD,
      screensEnum.MANUFACTURING,
      screensEnum.MANUFACTURING_DETAIL,
      screensEnum.PALLET,
      screensEnum.PALLET_PACKING,

      screensEnum.BOX,
      screensEnum.BOX_PACKING,

      screensEnum.CONTAINER,
      screensEnum.CONTAINER_DETAIL,
    ],
    notAllow: "all",
  },
  [RolesEnum.VietnameSale]: {
    allow: [ausabacoScreensEnum.CONTRACT],
    notAllow: "all",
  },
};

// Initialize routeRoleMap
screens.forEach((screen: TScreen) => {
  routeRoleMap[screen] = {};
  roles.forEach((role) => {
    const { allow, notAllow } = customRoleMap[role] || {};
    if (allow === "all" && notAllow?.length === 0) {
      routeRoleMap[screen][role] = true;
      return;
    }

    if (allow === "all") {
      routeRoleMap[screen][role] = !notAllow?.includes(screen);
      return;
    }

    if (notAllow === "all") {
      routeRoleMap[screen][role] = allow?.includes(screen);
      return;
    }
  });
});

const AUSA_ALLOW_ROUTE = ["CONTRACT", "PROFILE"];

export const getAllowAccessRoute = (routeId: TScreen) => {
  const role = store.getState().auth.user?.roleName;
  if (!role) {
    return false;
  }
  const roleInfo = routeRoleMap[routeId as TScreen];

  if (!roleInfo) {
    return true;
  }
  const is_good = !!roleInfo?.[role as RolesEnum];
  if (isAusa() && is_good) {
    return AUSA_ALLOW_ROUTE.includes(routeId);
  }

  return is_good && !AUSA_ALLOW_ROUTE.includes(routeId);
};

export const roleFeatures = {
  ALLOW_DELETE_CUSTOMER: [...AdminRoles],
  ALLOW_CREATE_CUSTOMER: [...AdminRoles, ...SalesRoles],
  ALLOW_UPDATE_CUSTOMER: [...AdminRoles, ...SalesRoles],

  VIEW_STOCK_VALUE: [
    ...AdminRoles,
    RolesEnum.VietnamAccounting,
    RolesEnum.Accounting,
    RolesEnum.AccountingManager,
  ],

  DELETE_STOCK: [...AdminRoles, RolesEnum.USWarehouse],

  ALLOW_DELETE_SO: [...AdminRoles],
  ALLOW_CREATE_SO: [...AdminRoles, ...SalesRoles],
  ALLOW_UPDATE_SO: [...AdminRoles, ...SalesRoles],

  APPROVE_SO: [...AdminRoles, ...SalesRoles],

  ALLOW_QUOTATION: [...AdminRoles, ...VietnamSalesRoles],

  ALLOW_ALL: [RolesEnum.CEO],

  ADMIN_ROLE: [...AdminRoles],

  ALLOW_EXPORT_CUSTOMER: [RolesEnum.CEO, RolesEnum.GeneralManager],

  ALLOW_CANCEL_PO: [RolesEnum.CEO, RolesEnum.GeneralManager],

  ALLOW_UPDATE_PRICE: [RolesEnum.VietnamAccounting, RolesEnum.CEO],

  ALLOW_UPDATE_PRODUCT: [RolesEnum.CEO, RolesEnum.VietnamPlanning],
  ALLOW_PO_COST_CHECK: [RolesEnum.VietnamAccounting],

  Processor_Approval_Manufacturing: [...AdminRoles, RolesEnum.ManagerProcessor],
  Manufacturing_Approval: [...AdminRoles, RolesEnum.VietnamPlanning],
  Accounting_Approval_Manufacturing: [
    ...AdminRoles,
    RolesEnum.VietnamAccounting,
  ],
  Manager_Approval_Manufacturing: [...AdminRoles, RolesEnum.ManagerProcessor],

  NOT_PO_EDIT: [RolesEnum.VietnamAccounting],
  NOT_PO_VIEW_PRICE: [RolesEnum.VietnamPlanning],

  VIETNAM_PLANNING: [RolesEnum.VietnamPlanning],
};

export const getPermission = (featureName: keyof typeof roleFeatures, user_id?: any) => {
  const user = store.getState().auth.user;
  const roleName = user?.roleName as RolesEnum;
  const allowRole = !!roleFeatures[featureName]?.includes?.(roleName)

  if (user_id) {
    return allowRole && user?.id === user_id
  }

  return allowRole;
};
