export const isCustomerDomain =
  window.location.host === process.env.REACT_APP_PUBLIC_CUSTOMER_HOST;

export const customerApiPrefix = isCustomerDomain ? "/customer-portal" : "";

export const STORAGE_KEYS = {
  COMMON_THEME: "COMMON_THEME",
  COMMON_LANGUAGE: "COMMON_LANGUAGE",
};

export enum PalletStatusEnum {
  ACTIVE = "Active",
  RECEIVED = "Received",
  SHIPPED = "Shipped",
}

export const DATE_FORMAT = {
  DATE_ONLY: "DD/MM/YYYY",
};

export const ITEM_TYPE = {
  fset: "Fixed Set",
  rset: "Random Set",
  n: "Spare part",
  y: "",
  bom: "BOM",
};

export const ITEM_TYPE_VALUE = {
  fset: "fset",
  rset: "rset",
  n: "n",
  y: "y",
  bom: "bom",
  ser: "ser",
};

export const ITEM_TYPE_V2 = {
  fset: "Fixed Set",
  rset: "Random Set",
  n: "Spare Part",
  y: "Individual",
  bom: "BOM",
  ser: "Service",
};

export type ItemTypeKey = keyof typeof ITEM_TYPE_V2;

export enum DeliveryTypeEnum {
  FULL = "Full Delivery",
  PARTIAL = "Partial Delivery",
}

export const DELIVERY_TYPE = [
  {
    label: DeliveryTypeEnum.FULL,
    value: DeliveryTypeEnum.FULL,
  },
  {
    label: DeliveryTypeEnum.PARTIAL,
    value: DeliveryTypeEnum.PARTIAL,
  },
];

export const DELIVERY_METHOD = ["Best Way", "Will Call", "UPS", "LTL"];

export const SHIPPING_COMPANY = [
  {
    label: "Daylights",
    value: "Daylights",
  },
  {
    label: "R & L",
    value: "R & L",
  },
];

// 01: Next Day Air
// 02: 2nd Day Air
// 03: Ground
// 12: 3 Day Select
// 13: Next Day Air Saver
// 14: UPS Next Day Air Early A.M.
// 59: 2nd Day Air A.M.

export const SHIPPING_SERVICES_RAW = [
  "Next Day Air",
  "2nd Day Air",
  "Ground",
  "3 Day Select",
  "Next Day Air Saver",
  "UPS Next Day Air Early A.M.",
  "2nd Day Air A.M.",
  "UPS Standard",
  "UPS Worldwide Express",
];

export const SHIPPING_SERVICES = SHIPPING_SERVICES_RAW.map((e) => {
  return {
    label: e,
    value: e,
  };
});

export const SHIPPING_COST_OPTIONS = [
  {
    label: "Ship without price inquiry",
    value: "Ship without price inquiry",
  },
  {
    label: "Pre-quote required before shipping",
    value: "Pre-quote required before shipping",
  },
];

export enum PRICE_LEVEL_ENUM {
  US_PRICE = "US Price",
  END_USER = "End User",
  FOB_PRICE = "FOB Price",
}

export const PRICE_LEVEL = [
  {
    label: "US Price",
    value: "US Price",
  },
  {
    label: "End User",
    value: "End User",
  },
];

export const SO_PRICE_LEVEL = [
  {
    label: "FOB Price",
    value: "FOB Price",
  },
  ...PRICE_LEVEL,
];

// 02: Customer Supplied Package
// 03: Tube
// 04: PAK
// 21: UPS Express Box
// 24: UPS 25KG Box
// 25: UPS 10KG Box
// 30: Pallet

export const PACKAGE_TYPE_RAW = [
  "Customer Supplied Package",
  "Pallet",
  "UPS 10KG Box",
  "UPS 25KG Box",
  "UPS Express Box",
  "Tube",
  "PAK",
];

export const PACKAGE_TYPE = PACKAGE_TYPE_RAW.map((e) => {
  return {
    label: e,
    value: e,
  };
});

export enum SOStatusEnum {
  DRAFT = "Draft",
  CANCELLED = "Cancelled",
  PENDING_APPROVAL = "Pending Approval",
  IN_REVIEW = "In Review",
  ON_HOLD = "On Hold",
  SENT_EMAIL = "Sent Email",
  BACKORDER_APPROVED = "Backorder Approved",
  AWAITING_CUSTOMER = "Awaiting Customer",
  CUSTOMER_APPROVED = "Customer Approved",
  AWAITING_MANUFACTURING = "Awaiting Manufacturing",
  READY_TO_PICK = "Ready to Pick",
  READY_TO_SHIP = "Ready to Ship",
  READY_TO_PACK = "Ready to Pack",
  PARTIALLY_SHIPPED = "Partially Shipped",
  SHIPPED = "Shipped",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  REJECTED_BY_CUSTOMER = "Customer Rejected",
  DELIVERED = "Delivered",
  COMPLETED = "Completed",
  DELETED = "Deleted",
}

export enum InvoiceStatusEnum {
  PAID = "Paid",
  UNPAID = "Unpaid",
  PARTIALLY_PAID = "Partially Paid",
}

export enum POStatusEnum {
  PENDING_APPROVAL = "Pending Approval",
  PROCESSOR_APPROVED = "Processor Approved",
  MANAGER_APPROVED = "Manager Approved",
  MANUFACTURING_APPROVED = "Manufacturing Approved",
  ACCOUNTING_APPROVED = "Accounting Approved",
  MANUFACTURING_IN_PROCESS = "Manufacturing in Process",
  AWAITING_SHIP = "Awaiting for Ship",
  SENT_TO_WAREHOUSE = "Shipping to Warehouse",
  RECEIVED_AT_WAREHOUSE = "Fully Received",
  RECEIVED_PARTIAL_AT_WAREHOUSE = "Partially Received",
  CANCELLED = "Cancelled",
}

export enum QuotationTypeEnum {
  FROM_FACTORY = 0,
  FROM_US_WH = 1,
}

export const QUOTATION_SHIP_FROM = [
  {
    label: "From VN Factory",
    value: QuotationTypeEnum.FROM_FACTORY,
  },
  {
    label: "From US Warehouse",
    value: QuotationTypeEnum.FROM_US_WH,
  },
];

export enum QuotationStatusEnum {
  ACTIVE = "Active",
  READY_FOR_CUSTOMER = "Ready for Customer",
  MEASURING_SHIPMENT = "Measuring Shipment",
  CALCULATING_SHIP_COST = "Calculating Ship Cost",
  MEASUREMENT_COMPLETED = "Measurement Completed",
  SENT_TO_CUSTOMER = "Sent to Customer",
  CUSTOMER_APPROVED = "Customer Approved",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
}

export const COLORS = [
  "#20A39E",
  "#CEA0AE",
  "#363635",
  "#6DB1BF",
  "#950952",
  "#2274A5",
  "#023284",
  "#E7EB90",
  "#ACECA1",
  "#00A676",
  "#F72C25",
  "#FCF300",
  "#072AC8",
  "#41337A",
];

// export enum ManufacturingStatusEnum {
//   [POStatusEnum.SENT_TO_MANUFACTURING]: 'Pending Approval'
// }

export const RETURN_OPTIONS = [
  {
    label: "Exchange",
    value: "Exchange",
  },
  {
    label: "Defect",
    value: "Defect",
  },
  {
    label: "Courtesy return",
    value: "Courtesy return",
  },
  {
    label: "Repair with warranty",
    value: "Repair with warranty",
  },
  {
    label: "Repair without warranty",
    value: "Repair without warranty",
  },
];

export const SERIAL_VERSIONS = Array.from({ length: 100 }, (_, i) =>
  i.toString().padStart(2, "0")
).map((v) => ({
  label: v,
  value: v,
}));

export const PACKAGE_SHIPMENT_STATUS = {
  PICKING: "Picking",
  PACKING: "Packing",
  READY_TO_SHIP: "Ready to Ship",
  SHIPPED: "Shipped",
};

export const PAYMENT_METHODS = [
  "ACH",
  "Check",
  "Credit Card",
  "Cash",
  "Others",
];

export enum TaskStatusEnum {
  OPEN = "Open",
  IN_PROGRESS = "In Progress",
  DONE = "Done",
  CANCELLED = "Cancelled",
}

export const TASK_STATUSES = [
  {
    label: TaskStatusEnum.OPEN,
    value: TaskStatusEnum.OPEN,
  },
  {
    label: TaskStatusEnum.IN_PROGRESS,
    value: TaskStatusEnum.IN_PROGRESS,
  },
  {
    label: TaskStatusEnum.DONE,
    value: TaskStatusEnum.DONE,
  },
  {
    label: TaskStatusEnum.CANCELLED,
    value: TaskStatusEnum.CANCELLED,
  },
];

export enum ContractStatusEnum {
  ACTIVE = "Active",
  APPROVED = "Approved",
  SENT = "Sent",
  SIGNED = "Signed",
  CANCEL = "Cancel",
}

export const CreatedByEnum = {
  1: "Staff",
  2: "Customer",
};
